import React, { useState, useRef, useEffect } from 'react';
import QueueService from './QueueService';
import './WhatsappChatModal.css';

const WhatsAppChatModal = ({ messages, phone_number, onClose, token, onMessageSent }) => {
  const [newMessage, setNewMessage] = useState('');
  const messagesEndRef = useRef(null);
  
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSend = async () => {
    if (!newMessage.trim()) return;

    try {
      const response = await QueueService.SendWhatsappMessage(phone_number, newMessage, token);
      if (response.status === "Success") {
        const newMsg = {
          message_text: newMessage,
          sent_by_system: true,
          timestamp: new Date().toISOString()
        };
        setNewMessage('');
        if (onMessageSent) {
          onMessageSent(newMsg);
        }
        messages.push(newMsg); }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <>
      <div className="modal-overlay" onClick={onClose} />
      <div className="whatsapp-chat-modal">
        <div className="chat-header">
          <h3>WhatsApp Chat</h3>
          <button className="whatsapp-close-button" onClick={onClose}>&times;</button>
        </div>
        
        <div className="chat-messages">
          {messages.map((msg, index) => (
            <div
              key={index}
              className={`message ${msg.sent_by_system ? 'system' : 'customer'}`}
            >
              <div className="message-content">
                <p>
                  {msg.message_text.length > 150 
                    ? `${msg.message_text.substring(0, 150)}...`
                    : msg.message_text}
                </p>
                <span className="timestamp">
                  {new Date(new Date(msg.timestamp).getTime() + (5 * 60 + 30) * 60000).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true,
                  })}
                </span>
              </div>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>

        <div className="chat-input">
          <textarea
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Type a message..."
          />
          <button onClick={handleSend} disabled={!newMessage.trim()}>
            Send
          </button>
        </div>
      </div>
    </>
  );
};

export default WhatsAppChatModal;