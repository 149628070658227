import React, { useState, useEffect, useRef } from 'react';
import QueueService from './QueueService'; // Or your relevant service

const ChatWindow = ({ phone, token }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef(null);

  // Re-fetch messages whenever phone changes

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };



  useEffect(() => {
    scrollToBottom();
  }, [messages]);



  useEffect(() => {
    const fetchMessages = async () => {
      if (!phone) return;
      try {
        const data = await QueueService.FetchMessages(phone);
        setMessages(data); // 'data' depends on your API response shape
      } catch (err) {
        console.error('Error fetching messages:', err);
      }
    };

    fetchMessages();
  }, [phone]);
  

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  // Send a message to the current phone
  // const handleSendMessage = async () => {
  //   if (!phone || !newMessage.trim()) return;

  //   try {
  //     // Replace 'yourAuthToken' with an actual token or prop
  //     await QueueService.SendMessage(phone, newMessage, 'yourAuthToken');
  //     // Update local UI right away (optimistic update)
  //     setMessages([...messages, { sender_id: 'me', message_text: newMessage }]);
  //     setNewMessage('');
  //   } catch (err) {
  //     console.error('Error sending message:', err);
  //   }
  // };


  const handleSendMessage = async () => {
    if (!phone || !newMessage.trim()) return;

    try {
      
      const response = await QueueService.SendWhatsappMessage(`91${phone}`, newMessage, token);
      // if your API returns { status: "Success" }
      if (response && response.status === 'Success') {
        // Create a new message object for local state
        const newMsg = {
          message_text: newMessage,
          sent_by_system: true, // Align to the right
          timestamp: new Date().toISOString(),
          id: Date.now(), // Temporary ID
        };
        setMessages((prev) => [...prev, newMsg]);
        setNewMessage('');
      }
    } catch (err) {
      console.error('Error sending message:', err);
    }
  };


  const formatTimestamp = (timeString) => {
    if (!timeString) return '';
    const rawDate = new Date(timeString);
    const withOffset = new Date(rawDate.getTime() + (5 * 60 + 30) * 60000); 
    return withOffset.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };


  // If no chat is selected, show a placeholder
  if (!phone) {
    return (
      <div className="chat-window-container">
        <div className="chat-window-header">No Chat Selected</div>
        <div className="chat-window-messages">
          <div className="chat-window-message-placeholder">
            Please select a chat from the list.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="chat-window-container">
      <div className="chat-window-header">Chat with {phone}</div>
      {/* <div className="chat-window-messages">
        {messages.map((msg, index) => (
          <div key={index} className="chat-window-message">
            {msg.message_text}
          </div>
        ))}
      </div> */}
        <div className="chat-messages">
        {loading && <div className="chat-window-loading">Loading messages...</div>}

        {!loading &&
          // messages.map((msg, index) => {
          //   // If sent_by_system === true => align to right. Otherwise left.
          //   const messageClass = msg.sent_by_system ? 'system' : 'customer';
          //   return (
          //     <div key={msg.id || index} className={`chat-bubble ${messageClass}`}>
          //       <p className="chat-text">
          //         {msg.message_text.length > 150
          //           ? `${msg.message_text.substring(0, 150)}...`
          //           : msg.message_text}
          //       </p>
          //       <span className="chat-timestamp">
          //         {formatTimestamp(msg.timestamp)}
          //       </span>
          //     </div>
          //   );
          // })
          messages.map((msg, index) => (
            <div
              key={index}
              className={`message ${msg.sent_by_system ? 'system' : 'customer'}`}
            >
              <div className="message-content">
                <p>
                  {msg.message_text.length > 150 
                    ? `${msg.message_text.substring(0, 150)}...`
                    : msg.message_text}
                </p>
                <span className="timestamp">
                  {new Date(new Date(msg.timestamp).getTime() + (5 * 60 + 30) * 60000).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true,
                  })}
                </span>
              </div>
            </div>
          ))}
          

        {/* Dummy div to ensure scroll to bottom */}
        <div ref={messagesEndRef} />
      </div>
      <div className="chat-window-input-container">
        <input
          type="text"
          placeholder="Type a message"
          className="chat-window-input"
          value={newMessage}
          onKeyPress={handleKeyPress}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <button className="chat-window-send-button" onClick={handleSendMessage}>
          Send
        </button>
      </div>
    </div>
  );
};

export default ChatWindow;
