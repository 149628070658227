import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import QueueService from './QueueService';
import { faQrcode, faCog, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import './MenuPage.css'; // Include CSS for styling



const MenuPage = ({ token, handleLogout, business_id }) => {
  const [businessInfo, setBusinessInfo] = useState({});

  useEffect(() => {
    const fetchBusinessInfo = async () => {
        try {
            const response = await QueueService.GetBusinessInfo(business_id); // Assuming business_id is passed internally or via another prop
            setBusinessInfo({
                reports_url: response.reports_url
            });
        } catch (error) {
            console.error('Error fetching business info:', error);
        }
    };
    fetchBusinessInfo();
  }, [token]);

  return (
    <div className="menu-container">
      <div className="menu-item" onClick={() => window.open('/qr-code', '_blank')}>
        <FontAwesomeIcon icon={faQrcode} size="lg" />
        <span style={{ marginLeft: '18px' }}>QR Code</span>
      </div>
      <div className="menu-item" onClick={() => window.open('/business-settings', '_blank')}>
        <FontAwesomeIcon icon={faCog} size="lg" />
        <span>Business Settings</span>
      </div>
      <div className="menu-item" onClick={() => window.open(businessInfo.reports_url, '_blank')}>
        <FontAwesomeIcon icon={faCog} size="lg" />
        <span>Reports</span>
      </div>
      <div className="menu-item" onClick={handleLogout}>
        <FontAwesomeIcon icon={faSignOutAlt} size="lg" />
        <span>Logout</span>
      </div>
    </div>
  );
};

export default MenuPage;
