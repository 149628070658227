import React, { useState, useEffect } from 'react';
import { FaPlusCircle, FaMinusCircle } from 'react-icons/fa';
import './NumberModal.css';

const NumberModal = ({ 
  first_table_vacant_time,  // A
  average_waiting_time,     // B
  max_waiting_number,       // n
  min_time_diff_between_entries,
  max_time_diff_between_entries,
  token, 
  queue_id, 
  handleNumberSubmit, 
  onClose 
}) => {
  const [currentA, setCurrentA] = useState(parseFloat(first_table_vacant_time));
  const [currentB, setCurrentB] = useState(parseFloat(average_waiting_time));
  const [currentN] = useState(parseInt(max_waiting_number));
  const [currentC, setCurrentC] = useState(0); // C will be calculated
  const [errorMessage, setErrorMessage] = useState(""); // Error message for B

  useEffect(() => {
    const newC = parseFloat(currentA) + (parseFloat(currentB) * (parseFloat(currentN) - 1)); 
    setCurrentC(parseFloat(newC.toFixed(2))); // Limit to 2 decimal places
  }, [currentA, currentB, currentN]);

  const handleAChange = (newA) => {
    setCurrentA(parseFloat(newA.toFixed(2))); // Limit to 2 decimal places
  };

  const handleBChange = (newB) => {
    if (newB < min_time_diff_between_entries) {
      setCurrentB(parseFloat(min_time_diff_between_entries.toFixed(2))); // Limit to 2 decimal places
      setErrorMessage(`B cannot be less than ${min_time_diff_between_entries}`);
    } else if (newB > max_time_diff_between_entries) {
      setCurrentB(parseFloat(max_time_diff_between_entries.toFixed(2))); // Limit to 2 decimal places
      setErrorMessage(`B cannot exceed ${max_time_diff_between_entries}`);
    } else {
      setCurrentB(parseFloat(newB.toFixed(2))); // Limit to 2 decimal places
      setErrorMessage(""); // Clear error message
    }
  };

  const handleSubmit = () => {
    handleNumberSubmit(queue_id, token, currentB, currentA);
  };

  return (
    <div className="number-modal-content">
      {/* Control for First Table Vacant Time (A) */}
      <div className="field-group">
        <button onClick={() => handleAChange(currentA - 1)} className="number-change-button">
          <FaMinusCircle />
        </button>
        <input
          type="number"
          step="0.1"
          value={currentA === 0 ? '' : currentA} 
          onChange={(e) => handleAChange(parseFloat(e.target.value))}
          className="number-input"
        />
        <button onClick={() => handleAChange(currentA + 1)} className="number-change-button">
          <FaPlusCircle />
        </button>
        <label>First Table Vacant Time (A)</label>
      </div>

      {/* Control for Average Waiting Time (B) */}
      <div className="field-group">
        <button onClick={() => handleBChange(currentB - 1)} className="number-change-button">
          <FaMinusCircle />
        </button>
        <input
          type="number"
          step="0.1"
          value={currentB === 0 ? '' : currentB}
          onChange={(e) => handleBChange(parseFloat(e.target.value))}
          className="number-input"
        />
        <button onClick={() => handleBChange(currentB + 1)} className="number-change-button">
          <FaPlusCircle />
        </button>
        <label>Average Waiting Time (B)</label>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </div>

      {/* Display for Next Waiting Time (C) */}
      <div className="field-group">
        <input
          type="number"
          step="0.01"
          value={currentC}
          readOnly
          className="number-input"
        />
        <label>Next Waiting Time (C)</label>
      </div>

      {/* Display Formula and Current N */}
      <div className="formula-display">
        <p>Formula: C = A + (B * (N - 1))</p>
        <p>Current N (Number of users in queue): {currentN - 1}</p>
      </div>

      {/* Submit Button */}
      <button onClick={handleSubmit} className="submit-button">
        Submit
      </button>
    </div>
  );
};

export default NumberModal;
