import React, { useState, useEffect, useRef } from "react";
import QueueService from "./QueueService";
import "./AllEnteredPage.css";
import CustomerHistoryModal from "./CustomerHistoryModal";
import { FaEdit, FaPlus, FaMedal } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import UserForm from "./UserForm";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import CustomModal from "./CustomModal";
import AddRemarkModal from "./AddRemarkModal";
import Modal from "./Modal";
import debounce from 'lodash.debounce';
import Message from "./Message";
const apiUrl = process.env.REACT_APP_API_URL;

const BASE_URL = `${apiUrl}/business`;

const AllEnteredPage = ({ token, business_id }) => {
  const [customers, setCustomers] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [queue_info, setQueueInfo] = useState([]);
  const [exitTime, setExitTime] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRemarkModal, setShowRemarkModal] = useState(false);
  const [remarkUserId, setRemarkUserId] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [queue_auto, setQueueAutoSelect] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [message, setMessage] = useState(null);
  const [editValue, setEditValue] = useState("");
  const [selectedColumn, setSelectedColumn] = useState("");
  const [customStartDate, setCustomStartDate] = useState("");
  const [customEndDate, setCustomEndDate] = useState("");
  const [tempValue, setTempValue] = useState("");
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [tables, setTables] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedRange, setSelectedRange] = useState("today");
  const [customer_name, setCustomerName] = useState(false);
  const filterDropdownRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [showTableModal, setShowTableModal] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [selectedTable, setSelectedTable] = useState("");
  const [customTable, setCustomTable] = useState("");
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const dropdownRef = React.useRef(null);
  const offlineMutationsKey = "offlineMutations";
  const isProcessingRef = useRef(false);
  const [loading, setLoading] = useState(false);
  const [showCustomModal, setShowCustomModal] = useState(false);
  const [statusChangedata, setstatusChangedata] = useState({
    customer:null,
    newStatus:null
  });
  

  const toggleDropdown = (index) => {
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };


  

  const getOfflineMutations = () => {
    try {
      const stored = localStorage.getItem(offlineMutationsKey);
      return stored ? JSON.parse(stored) : [];
    } catch (error) {
      console.error("Error parsing offline mutations:", error);
      return [];
    }
  };

  // Helper: add a new offline mutation into localStorage
  const addOfflineMutation = (mutation) => {
    const offlineMutations = getOfflineMutations();
    offlineMutations.push(mutation);
    localStorage.setItem(offlineMutationsKey, JSON.stringify(offlineMutations));
    console.log("Added offline mutation");
    console.log(offlineMutations);
  };


  const processOfflineMutations = async () => {
    if (isProcessingRef.current) {
      console.log("Already processing mutations. Exiting.");
      return;
    }
    isProcessingRef.current = true;

    try {
      if (!navigator.onLine) {
        console.log("Offline. Exiting processing.");
        return;
      }

      let offlineMutations = getOfflineMutations();
      if (offlineMutations.length === 0) {
        console.log("No offline mutations to process.");
        return;
      }

      console.log(`Processing ${offlineMutations.length} offline mutations.`);

      for (let i = 0; i < offlineMutations.length; i++) {
        const mutation = offlineMutations[i];
        try {
          console.log('Processing mutation:', mutation.type, mutation.payload);
          if (mutation.type === "add") {
            const newUser = await QueueService.AddUser(mutation.payload, token);
            // Update local customers with correct ID if necessary
            // Example:
            // setCustomers(prev => prev.map(c => c.customer_id === mutation.tempId ? { ...c, customer_id: newUser.customer_id } : c));
          } else if (mutation.type === "edit") {
            await QueueService.EditUser(mutation.payload, token);
          }
          // Mark mutation as done
          offlineMutations[i].done = true;
          console.log("Successfully processed mutation:", mutation.type);
        } catch (error) {
          console.error("Error processing mutation:", mutation, error);
          // Decide to retry or discard
        }
      }

      // Remove completed mutations
      offlineMutations = offlineMutations.filter((m) => !m.done);
      localStorage.setItem(offlineMutationsKey, JSON.stringify(offlineMutations));
      console.log("Finished processing mutations. Remaining:", offlineMutations.length);
      fetchCustomers();
    } finally {
      isProcessingRef.current = false;
    }
  };

  useEffect(() => {
    const handleOnline = debounce(() => {
      console.log("Went online, attempting to process offline mutations.");
      processOfflineMutations();
    }, 1000); // Adjust the debounce delay as needed

    window.addEventListener("online", handleOnline);

    // Attempt to process pending mutations immediately if online
    if (navigator.onLine) {
      processOfflineMutations();
    }

    return () => {
      window.removeEventListener("online", handleOnline);
      handleOnline.cancel(); // Cancel any pending debounced calls on cleanup
    };
  }, []);


  const highlightText = (text, query) => {
    if (!query) return text;

    const parts = text.toString().split(new RegExp(`(${query})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === query ? <mark key={index}>{part}</mark> : part
    );
  };

  const filteredCustomers = customers.filter((customer) =>
    Object.values(customer).some(
      (value) =>
        value != null && value.toString().toLowerCase().includes(searchQuery)
    )
  );

  const fetchTables = async (token) => {
    const res = await QueueService.fetchTables(token);
    console.log(res);
    setTables(res);
  };

  // Function to handle clicks outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        filterDropdownRef.current &&
        !filterDropdownRef.current.contains(event.target)
      ) {
        setShowFilters(false); // Close the dropdown
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filterDropdownRef]);

  const startEditing = (index, field, currentValue) => {
    setEditingIndex(index);
  };

  const fetchBusinessInfo = async (business_id) => {
    await QueueService.GetBusinessInfo(business_id)
      .then((response) => {
        if (response.queue_info && response.queue_info.length > 0) {
          setQueueInfo(response.queue_info);
          setQueueAutoSelect(
            response.business_metadata.automatic_queue_selection
          );
        }
      })
      .catch(console.error);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdownIndex(null);
        setCurrentCustomer(null);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCancel = () => {
    setEditingIndex(null);
    setTempValue("");
  };

  const handleDeleteUser = (userId, customer_name) => {
    setSelectedUser(userId);
    setShowDeleteModal(true);
    setCustomerName(customer_name);
    setOpenDropdownIndex(null);
  };

  const handleAddUser = () => {
    setShowForm(true);
  };

  const handleFormSubmit = async (user) => {
    const userPayload = {
      ...user,
      business_id,
    };
    setShowForm(false);

    // Prepare extra fields for a complete customer object
    const extraFields = {
      badge: null,
      brand_name: null,
      created_at: new Date().toISOString(),
      // customer_id: Date.now(), // Temporary ID for optimistic update
      delete_time: null,
      entry_time: null,
      estimated_arrival_time: null,
      exit_time: null,
      initial_waiting_number: 0,
      initial_waiting_time: null,
      initial_waiting_time_model: null,
      mapping_id: null,
      queue_name: null,
      removed_reason: null,
      status: "syncing...",
      table_id: null,
      notes: [],
    };

    // Check if offline
    const isOffline = !navigator.onLine;

    if (currentUser) {
      // Editing existing user
      const updatedCustomers = customers.map((customer) =>
        customer.customer_id === currentUser.customer_id
          ? { ...customer, ...user, ...extraFields }
          : customer
      );
      setCustomers(updatedCustomers); // Optimistic local update

      // Prepare payload for API
      const apiPayload = {
        ...user,
        business_id,
        customer_id: currentUser.customer_id,
      };

      if (isOffline) {
        // Queue offline edit mutation
        addOfflineMutation({
          type: "edit",
          payload: apiPayload,
        });
      } else {
        // Attempt API call directly
        try {
          await QueueService.EditUser(apiPayload, token);
        } catch (error) {
          console.error("Error editing user:", error);
          // Optionally queue the mutation if it fails
          addOfflineMutation({
            type: "edit",
            payload: apiPayload,
          });
        }
      }
    } else {
      // Adding a new user
      const newCustomer = { ...user, ...extraFields };
      setCustomers([newCustomer, ...customers]); // Optimistically add to local list

      if (isOffline) {
        // Queue offline add mutation
        addOfflineMutation({
          type: "add",
          payload: userPayload
        });
      } else {
        
        try {
          const newUser = await QueueService.AddUser(userPayload, token);
        } catch (error) {
          console.error("Error adding user:", error);
          addOfflineMutation({
            type: "add",
            payload: userPayload,
            tempId: newCustomer.customer_id,
          });
        }
      }
    }
    fetchCustomers();
    
  };
  

  const downloadCSV = () => {
    const headers = [
      "S.No",
      "Name",
      "Phone",
      "People",
      "Table Seating",
      "Status",
      "Table No",
      "Initial Waiting Number",
      "Total Waiting Time(mins)",
      "Arrived At",
      "Entry Time",
      "Delete Time",
      "Exit Time",
      "Notes",
      "Deleted Reason",
    ];

    // Prepare the CSV content
    const csvContent = customers.map((customer, index) => {
      const notesText = customer.notes
        ? customer.notes
            .map(
              (note) =>
                `${formatNoteTime(note.note_created_at)}: ${note.note_text}`
            )
            .join(" | ")
        : "";

      const totalWaitingTime = Math.floor(
        (new Date(customer.entry_time) - new Date(customer.created_at)) /
          1000 /
          60
      );

      return [
        index + 1,
        customer.name,
        customer.phone,
        customer.no_of_people,
        customer.queue_name,
        customer.status,
        customer.table_no || "",
        Math.round(customer.initial_waiting_number) || "",
        totalWaitingTime || "",
        `"${formatDateTime(customer.created_at)}"`,
        `"${formatDateTime(customer.entry_time)}"`,
        `"${formatDateTime(customer.delete_time)}"`,
        customer.exit_time ? `"${formatDateTime(customer.exit_time)}"` : "",
        notesText,
        customer.removed_reason || "",
      ].join(",");
    });
    const csvData = [headers.join(","), ...csvContent].join("\n");
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "customers_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const getEpochTimeRange = (rangeType) => {
    const now = new Date();
    let startTime, endTime;

    if (rangeType === "today") {
      startTime =
        new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          6
        ).getTime() / 1000;
      endTime = startTime + 24 * 3600;
    } else if (rangeType === "yesterday") {
      const yesterday = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 1,
        6
      );
      startTime = yesterday.getTime() / 1000;
      endTime = startTime + 24 * 3600;
    } else if (rangeType === "custom") {
      startTime = new Date(customStartDate).getTime() / 1000;
      endTime = new Date(customEndDate).getTime() / 1000 + 24 * 3600;
    }

    return { startTime, endTime };
  };

  const fetchCustomers = async () => {
    const { startTime, endTime } = getEpochTimeRange(selectedRange);
    const params = {
      start_time: startTime,
      end_time: endTime,
    };

    setLoading(true);
    try {
      const response = await QueueService.CustomerInfo(params, token);
      if (response) {
        setCustomers(response);
      } else {
        console.error("Failed to fetch customer info:", response.errors);
      }
    } catch (error) {
      console.error("Error fetching customer info:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log(token);
    fetchCustomers(); // Fetch customers on initial load and whenever selectedRange changes
  }, [selectedRange, customStartDate, customEndDate]);

  useEffect(() => {
    if (token) {
      fetchTables(token);
      fetchBusinessInfo(business_id);
    }
  }, [token, business_id]); // Adding dependencies ensures the effect runs when these values change

  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) return "";

    const date = new Date(dateTimeString);

    // Convert the UTC time to IST by adding 5 hours and 30 minutes
    const offsetInMinutes = 5 * 60 + 30; // 5 hours and 30 minutes in minutes
    const istDate = new Date(date.getTime() + offsetInMinutes * 60000);

    return istDate.toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata",
      hour12: true,
      day: "2-digit",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const handleExitTimeChange = (event, index) => {
    setExitTime(event.target.value);
    setEditingIndex(index);
  };

  const formatExitTimeForEditing = (exitTime) => {
    if (!exitTime) return "";

    const utcDate = new Date(exitTime);
    const offsetInMinutes = 5 * 60 + 30;
    const istDate = new Date(utcDate.getTime() + offsetInMinutes * 60000);

    return istDate.toISOString().slice(0, 16);
  };

  const handleExitTimeBlur = (customer) => {
    if (exitTime) {
      const istDate = new Date(exitTime);
      const offsetInMinutes = 5 * 60 + 30;
      const utcDate = new Date(istDate.getTime());
      const exitTimeUnix = Math.floor(utcDate.getTime() / 1000);

      const updatedCustomers = customers.map((c, idx) => {
        if (idx === editingIndex) {
          return { ...c, exit_time: utcDate.toISOString() };
        }
        return c;
      });
      setCustomers(updatedCustomers);
      setEditingIndex(null);

      const data = {
        mapping_id: customer.mapping_id,
        exit_time: exitTimeUnix,
      };

      fetch(`${BASE_URL}/update_mapping`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Exit time updated successfully:", data);
        })
        .catch((error) => {
          console.error("Error updating exit time:", error);
        });
      fetchCustomers();
    }
  };

  const confirmAddRemark = async (remark) => {
    try {
      await QueueService.add_note(token, remarkUserId, remark);
      setShowRemarkModal(false);
      setRemarkUserId(null);
    } catch (error) {
      console.error("Error marking user as entered:", error);
    }
  };

  const formatNoteTime = (noteCreatedAt) => {
    const utcDate = new Date(noteCreatedAt);
    const offsetInMinutes = 5 * 60 + 30;
    const istDate = new Date(utcDate.getTime() + offsetInMinutes * 60000);

    return istDate.toLocaleTimeString("en-IN", {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const handleEditChange = (event, index, column) => {
    const value = event.target.value;
    setEditValue(value);
    setEditingIndex(index);
    setSelectedColumn(column);
  };

  const add_note = (userId) => {
    setRemarkUserId(userId);
    setShowRemarkModal(true);
    setOpenDropdownIndex(null);
  };

  const confirmDeleteUser = async (reason) => {
    try {
      await QueueService.DeleteUser(selectedUser, reason, business_id);
      setShowDeleteModal(false);
    } catch (error) {
      console.error("Error deleting user:", error);
    }
    fetchCustomers();
    fetchBusinessInfo(business_id);
  };

  const handleSubmit = async (customer, field, newStatus) => {
    console.log("New status:", tempValue);
    try {
      let apiUrl;
      let apiPayload;

      if (field === "status") {
        if (newStatus === "entered") {
          // When changing status to 'entered', call the `update_mapping` API with `mark_as_active: true`
          apiUrl = `${BASE_URL}/update_mapping?auth_token=${token}`;
          apiPayload = {
            mapping_id: customer.mapping_id,
            mark_as_entered: true,
          };
        } else if (newStatus === "active") {
          apiUrl = `${BASE_URL}/add_or_update_user`;
          const { phone, estimated_arrival_time, ...customerDataWithoutPhone } =
            customer;
          apiPayload = {
            ...customerDataWithoutPhone,
          };
        }
        else if(newStatus === "inactive") {
          apiUrl = `${BASE_URL}/update_mapping?auth_token=${token}`;
          apiPayload = {
            mapping_id: customer.mapping_id,
            mark_as_delete: true,
          };
        }

      }

      // Call the respective API
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(apiPayload),
      });

      const data = await response.json();
      if (response.ok) {
        console.log(`${field} updated successfully:`, data);
           // Update the UI after successful API call
      const updatedCustomers = customers.map((c, idx) => {
        if (idx === editingIndex) {
          return { ...c, status: tempValue };
        }
        return c;
      });

      setCustomers(updatedCustomers);
      setEditingIndex(null);
      setTempValue("");
      setShowCustomModal(false);
        
      } else {
        console.error(`Error updating ${field}:`, data);
        setShowCustomModal(false);
        setMessage({ type: 'error', text: 'Cannot Update Status' });
        setTimeout(() => setMessage(null), 3000);
      }

   
    } catch (error) {
      console.error(`Error updating ${field}:`, error);
      
    }
    fetchCustomers();
  };

  const handleStatusChange = (customer, newStatus) => {
    setTempValue(newStatus);
    setstatusChangedata({
      customer,
      newStatus
    });
    setShowCustomModal(true);
  };


  const handleCustomerClick = (customerId) => {
    setSelectedCustomerId(customerId);
    setShowHistoryModal(true);
  };

  const handleEditPax = async (customer) => {
    if (editValue) {
      const updatedCustomers = customers.map((c, idx) => {
        if (idx === editingIndex) {
          return { ...c, [selectedColumn]: editValue };
        }
        return c;
      });
      setCustomers(updatedCustomers);
      setEditingIndex(null);

      const data = {
        mapping_id: customer.mapping_id,
        [selectedColumn]: Number(editValue),
      };

      await fetch(`${BASE_URL}/update_mapping`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(`${selectedColumn} updated successfully:`, data);
        })
        .catch((error) => {
          console.error(`Error updating ${selectedColumn}:`, error);
        });
      fetchCustomers();
    }
  };

  const formatColumnContent = (customer, column) => {
    if (column === "exit_time") {
      return customer.exit_time ? formatDateTime(customer.exit_time) : "";
    }
    return customer[column] || "_";
  };

  const handleTableModalOpen = (customer) => {
    setCurrentCustomer(customer);
    setShowTableModal(true);
    setSelectedTable(customer.table_no || "");
    setCustomTable("");
  };

  const handleTableModalClose = () => {
    setShowTableModal(false);
    setCurrentCustomer(null);
    setSelectedTable("");
    setCustomTable("");
  };

  const handleEditUser = (user) => {
    setCurrentUser(user);
    setShowForm(true);
    setOpenDropdownIndex(null);
  };

  const handleTableSelect = async () => {
    const tableName = selectedTable || customTable;
    if (currentCustomer && tableName) {
      const updatedCustomers = customers.map((c) =>
        c.mapping_id === currentCustomer.mapping_id
          ? { ...c, table_no: tableName }
          : c
      );
      setCustomers(updatedCustomers);

      // Prepare data for API call
      const oldTableId = currentCustomer.table_id || null; // Use null if no previous table was selected
      const newTableId =
        tables.find((table) => table.name === tableName)?.id || null;

      const data = {
        mapping_id: currentCustomer.mapping_id,
        old_table_id: oldTableId,
        table_id: newTableId,
        table_no: tableName,
      };

      try {
        const response = await fetch(`${BASE_URL}/update_mapping`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify(data),
        });

        const result = await response.json();
        if (response.ok) {
          console.log("Table updated successfully:", result);
        } else {
          console.error("Error updating table:", result);
        }
      } catch (error) {
        console.error("Error updating table:", error);
      }

      handleTableModalClose();
    }
  };

  return (
    <div className="all-entered-page">
     
      <div className="toolbar">
        <div className="filter-dropdown" ref={filterDropdownRef}>
          <div className="search-container">
            <input
              type="text"
              placeholder="🔍  &nbsp; Search..."
              value={searchQuery}
              onChange={handleSearchChange}
              className="search-input"
            />
          </div>

          <div className="filter-button-container">
            <button
              className="filter-button"
              onClick={() => setShowFilters(!showFilters)}
            >
              {selectedRange === "custom"
                ? `${customStartDate || "Start Date"} - ${customEndDate || "End Date"}`
                : selectedRange.charAt(0).toUpperCase() +
                  selectedRange.slice(1)}
              <span className="arrow-down-filter">▼</span>{" "}
              {/* Downward arrow */}
            </button>

            {showFilters && (
              <div className="filter-options">
                <button
                  onClick={() => {
                    setSelectedRange("today");
                    setShowFilters(false); // Close dropdown after selection
                  }}
                >
                  Today
                </button>
                <button
                  onClick={() => {
                    setSelectedRange("yesterday");
                    setShowFilters(false); // Close dropdown after selection
                  }}
                >
                  Yesterday
                </button>
                <button
                  onClick={() => {
                    setSelectedRange("custom");
                    // Keep dropdown open for custom date selection
                  }}
                >
                  Custom
                </button>

                {/* Display custom date pickers when "Custom" is selected */}
                {selectedRange === "custom" && (
                  <div className="custom-date-picker">
                    <input
                      type="date"
                      value={customStartDate}
                      onChange={(e) => setCustomStartDate(e.target.value)}
                    />
                    <input
                      type="date"
                      value={customEndDate}
                      onChange={(e) => setCustomEndDate(e.target.value)}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div
          className="download-csv"
          onClick={downloadCSV}
          style={{ cursor: "pointer" }}
        >
          <span className="icon-circle">
            <FiDownload />{" "}
          </span>{" "}
          {/* Downward arrow icon */}
        </div>
      </div>

      <div>
        <p style={{ margin: "10px 0px 0px 10px" }}>
          total{" "}
          {
            filteredCustomers.filter(
              (customer) => customer.status === "entered"
            ).length
          }{" "}
          entries,
          {filteredCustomers
            .filter((customer) => customer.status === "entered")
            .reduce((total, customer) => total + customer.no_of_people, 0)}{" "}
          pax, lunch:
          {
            filteredCustomers.filter((customer) => {
              const entryTime = new Date(customer.entry_time); // Assuming entry_time is in a parseable format
              const hour = entryTime.getHours();
              const minute = entryTime.getMinutes();
              return (
                customer.status === "entered" &&
                hour >= 4 && // Includes from 9:30 AM IST
                hour < 13
              ); // time in utc || (hour === 18 && minute < 30)); // Excludes 6:30 PM ist onwards
            }).length
          }{" "}
          entries,
          {filteredCustomers
            .filter((customer) => {
              const entryTime = new Date(customer.entry_time); // Assuming entry_time is in a parseable format
              const hour = entryTime.getHours();
              const minute = entryTime.getMinutes();
              return (
                customer.status === "entered" &&
                hour >= 4 && // Includes from 9:30 AM ist
                hour < 13
              ); // || (hour === 18 && minute < 30)); // Excludes 6:30 PM ist onwards
            })
            .reduce((total, customer) => total + customer.no_of_people, 0)}{" "}
          pax Dinner:
          {
            filteredCustomers.filter((customer) => {
              const entryTime = new Date(customer.entry_time); // Assuming entry_time is in a parseable format
              const hour = entryTime.getHours();
              const minute = entryTime.getMinutes();
              return customer.status === "entered" && hour >= 13; //|| (hour === 18 && minute > 30)); //  6:30 PM ist onwards
            }).length
          }{" "}
          entries,
          {filteredCustomers
            .filter((customer) => {
              const entryTime = new Date(customer.entry_time); // Assuming entry_time is in a parseable format
              const hour = entryTime.getHours();
              const minute = entryTime.getMinutes();
              return customer.status === "entered" && hour >= 13; // || (hour === 18 && minute > 30)); //  6:30 PM ist onwards
            })
            .reduce((total, customer) => total + customer.no_of_people, 0)}{" "}
          pax
        </p>
      </div>

    

      

      <table className="customers-table">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Name</th>
            <th>
              Table No <FaEdit />
            </th>
            <th>
              Status <FaEdit />
            </th>
            <th>Phone</th>
            <th>People</th>
            <th>Table Seating</th>
            <th>Initial Waiting Number</th>
            <th>Initial Waiting Time</th>
            <th>Total Waiting Time(mins)</th>
            <th>Waiting Time by AI(mins)</th>
            <th style={{ minWidth: "150px" }}>Arrived At</th>
            <th style={{ minWidth: "150px" }}>Entry Time</th>
            <th style={{ minWidth: "150px" }}>Delete Time</th>
            <th style={{ minWidth: "150px" }}>
              Exit Time <FaEdit />
            </th>
            <th style={{ minWidth: "150px" }}>Est. Arrival Time</th>
            <th>Notes</th>
            <th>Rating</th>
            <th>Food Rating</th>
            <th>Service Rating</th>
            <th>Ambiance Rating</th>
            <th>User Feedback</th>
            <th>
              Deleted Reason <FaEdit />
            </th>
          </tr>
        </thead>
        <tbody>

        {loading && <div className="loading-sign">Loading...</div>}

          {filteredCustomers.map((customer, index) => (
            <tr key={index}>
              <td
                onClick={() => toggleDropdown(index)}
              >
                {index + 1}

                {/* Render dropdown if this is the open cell */}
                <div className="dropdown" style={{ position: 'relative' }}>
                {openDropdownIndex === index && (
                  <div
                    className="dropdown-menu"
                    ref={dropdownRef}
                    style={{ position: 'absolute', left: 0 }}
                  >
                    <button onClick={() => handleEditUser(customer)}>Edit</button>
                    <button onClick={() => add_note(customer.mapping_id)}>Add Remark</button>
                    <button
                      onClick={() =>
                        (window.location.href = `https://wa.me/91${customer.phone}`)
                      }
                    >
                      WhatsApp
                    </button>
                    <button onClick={() => handleDeleteUser(customer.mapping_id, customer.name)}>
                      Delete
                    </button>
                  </div>
                )}
                </div>
              </td>


              <td
                style={{
                  cursor: "pointer",
                  position: "relative",
                  padding: "12px 8px", // Increased padding
                  whiteSpace: "nowrap", // Prevent wrapping for the badge and start of the name
                  overflow: "hidden", // Hide overflow
                  textOverflow: "ellipsis",
                  minWidth: "120px",
                  textAlign: "left", // Add ellipsis for overflow text
                }}
                onClick={() => handleCustomerClick(customer.customer_id)}
              >
                {customer.badge && customer.badge !== "NA" && (
                  <span
                    className={
                      customer.badge === "gold" ? "gold-badge" : "silver-badge"
                    }
                    style={{ display: "inline-block", verticalAlign: "middle" }} // Ensure inline-block display
                  ></span>
                )}
                <span
                  style={{
                    marginLeft: "5px", // Adjusted to marginLeft for the name
                    display: "inline",
                    verticalAlign: "middle", // Align vertically with the badge
                    whiteSpace: "normal", // Allow wrapping for the name
                    overflow: "visible", // Ensure the name can wrap
                  }}
                >
                  {highlightText(customer.name, searchQuery)}
                </span>
              </td>
              <td>
                {customer.status === "entered" ? (
                  <span
                    onClick={() => handleTableModalOpen(customer)}
                    className="editable"
                  >
                    {formatColumnContent(customer, "table_no")}
                  </span>
                ) : (
                  <span>{formatColumnContent(customer, "table_no")}</span>
                )}
              </td>

              <td>
                {index === editingIndex && selectedColumn === "status" ? (
                  <>
                    {customer.status === "inactive" && (
                      <>
                        <button
                          className="status-button waiting"
                          onClick={() => handleStatusChange(customer, "active")}
                        >
                          Mark Waiting in Queue
                        </button>
                        <button
                          className="status-button entered"
                          onClick={() =>
                            handleStatusChange(customer, "entered")
                          }
                        >
                          Mark as Entered
                        </button>
                      </>
                    )}
                    {customer.status === "deleted" && (
                      <>
                        <button
                          className="status-button waiting"
                          onClick={() => handleStatusChange(customer, "active")}
                        >
                          Mark Waiting in Queue
                        </button>
                        <button
                          className="status-button entered"
                          onClick={() =>
                            handleStatusChange(customer, "entered")
                          }
                        >
                          Mark as Entered
                        </button>
                      </>
                    )}
                    {customer.status === "entered" && (
                      <>
                        <button
                          className="status-button waiting"
                          onClick={() => handleStatusChange(customer, "active")}
                        >
                          Mark Waiting in Queue
                        </button>
                        <button
                          className="status-button delete"
                          onClick={() =>
                            handleStatusChange(customer, "inactive")
                          }
                        >
                          Delete User
                        </button>
                      </>
                    )}
                    {customer.status === "active" && (
                      <button
                        className="status-button entered"
                        onClick={() => handleStatusChange(customer, "entered")}
                      >
                        Mark as Entered
                      </button>
                    )}
                    <button
                      className="status-button cancel"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <span
                    onClick={() => {
                      if (customer.status !== "active") {
                        startEditing(index, "status", customer.status);
                        setSelectedColumn("status");
                      }
                    }}
                    className="editable"
                  >
                    <span className={`status-label ${customer.status}`}>
                      {customer.status === "active"
                        ? "Waiting"
                        : customer.status === "inactive"
                          ? "Deleted"
                          : customer.status.charAt(0).toUpperCase() +
                            customer.status.slice(1)}
                    </span>
                  </span>
                )}
              </td>

              <td>{highlightText(customer.phone, searchQuery)}</td>
              <td>
                {customer.status === "entered" &&
                index === editingIndex &&
                selectedColumn === "no_of_people" ? (
                  <input
                    type="number"
                    value={editValue}
                    onChange={(event) =>
                      handleEditChange(event, index, "no_of_people")
                    }
                    onBlur={() => handleEditPax(customer)}
                  />
                ) : (
                  <span
                    onClick={() => {
                      setEditingIndex(index);
                      setSelectedColumn("no_of_people");
                      setEditValue(customer.no_of_people || "");
                    }}
                    className="editable"
                  >
                    {customer.no_of_people}
                  </span>
                )}
              </td>

              <td>{highlightText(customer.queue_name, searchQuery)}</td>
              <td>
                {customer.initial_waiting_number
                  ? Math.round(customer.initial_waiting_number)
                  : ""}
              </td>
              <td>{customer.initial_waiting_time}</td>
              <td>
                {" "}
                {Math.max(
                  0,
                  Math.floor(
                    (new Date(customer.entry_time) -
                      new Date(customer.created_at)) /
                      1000 /
                      60
                  )
                )}{" "}
              </td>
              <td>
                {customer.initial_waiting_time_model
                  ? Math.round(customer.initial_waiting_time_model)
                  : ""}
              </td>
              <td>{formatDateTime(customer.created_at)}</td>
              <td>{formatDateTime(customer.entry_time)}</td>
              <td>{formatDateTime(customer.delete_time)}</td>
              <td>
                {customer.status === "entered" && index === editingIndex ? (
                  <input
                    type="datetime-local"
                    value={exitTime}
                    onChange={(event) => handleExitTimeChange(event, index)}
                    onBlur={() => handleExitTimeBlur(customer)}
                    className="exit-time-input"
                  />
                ) : (
                  <span
                    onClick={() => {
                      if (customer.status === "entered") {
                        setEditingIndex(index);
                        setExitTime(
                          customer.exit_time
                            ? formatExitTimeForEditing(customer.exit_time)
                            : ""
                        );
                      }
                    }}
                    className={customer.status === "entered" ? "editable" : ""}
                  >
                    {customer.exit_time
                      ? formatDateTime(customer.exit_time)
                      : "_"}
                  </span>
                )}
              </td>
              <td>{formatDateTime(customer.estimated_arrival_time)}</td>
              <td>
                {customer.notes &&
                  customer.notes.length > 0 &&
                  customer.notes.map((note, idx) => (
                    <div key={note.note_id}>
                      {formatNoteTime(note.note_created_at)}: {note.note_text}
                      {idx < customer.notes.length - 1 && " | "}
                    </div>
                  ))}
              </td>
              <td>{customer.review ? customer.review.rating : ''}</td>
              <td>{customer.review ? customer.review.food_rating : ''}</td>
              <td>{customer.review ? customer.review.service_rating : ''}</td>
              <td>{customer.review ? customer.review.ambience_rating : ''}</td>
              <td>{customer.review ? customer.review.text : ''}</td>
              <td>{customer.removed_reason}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {showForm && ( <Modal onClose={() => setShowForm(false)}> <UserForm onSubmit={handleFormSubmit} queue_info={queue_info} auto_queue={queue_auto}usercheck={false} isPhoneRequired={false} business_id={business_id}/> </Modal> )}
      {showForm && (
        <Modal onClose={() => {
          setShowForm(false);
          setCurrentUser(null);
        }}>
          {" "}
          <UserForm
            onSubmit={handleFormSubmit}
            allentered_check={true}
            initialData={currentUser}
            queue_info={queue_info}
            auto_queue={queue_auto}
            usercheck={false}
            isPhoneRequired={false}
            business_id={business_id}
            tables={tables}
          />{" "}
        </Modal>
      )}
      <button onClick={handleAddUser} className="add-button-all-customers">
        <FaPlus size={20} />
      </button>
      {showTableModal && (
        <Modal onClose={handleTableModalClose}>
          <div className="confirm-entry-modal">
            <p className="h1style">Assign/Change table</p>
            <div className="form-group">
              <label>Select a Table:</label>
              {tables.length > 0 ? (
                <select
                  value={selectedTable}
                  onChange={(e) => setSelectedTable(e.target.value)}
                  className="form-control"
                >
                  <option value="">None</option>
                  {tables.map((table) => (
                    <option key={table.id} value={table.name}>
                      {table.name} (Status: {table.status})
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  type="text"
                  placeholder="Enter table number"
                  value={customTable}
                  onChange={(e) => setCustomTable(e.target.value)}
                  className="form-control"
                />
              )}
            </div>
            <div className="actions">
              <button onClick={handleTableModalClose} className="cancel-button">
                Cancel
              </button>
              <button onClick={handleTableSelect} className="confirm-button">
                Confirm
              </button>
            </div>
          </div>
        </Modal>
      )}

      {showHistoryModal && (
        <CustomerHistoryModal
          customerId={selectedCustomerId}
          token={token}
          onClose={() => setShowHistoryModal(false)}
        />
      )}
      {showRemarkModal && (
        <AddRemarkModal
          onClose={() => setShowRemarkModal(false)}
          onConfirm={confirmAddRemark}
        />
      )}
      {showDeleteModal && (
        <ConfirmDeleteModal
          onClose={() => setShowDeleteModal(false)}
          onConfirm={confirmDeleteUser}
          userType="admin"
          customer_name={customer_name}
        />
      )}

        {showCustomModal && (
                <CustomModal
                  title={`Confirm Status Change(${statusChangedata.customer.name}) to ${statusChangedata.newStatus}`}
                  subtitle={`Are you sure you want to change the status to ${statusChangedata.newStatus}?`}
                  onConfirm={() => handleSubmit(statusChangedata.customer, "status", statusChangedata.newStatus)}
                  onClose={() => setShowCustomModal(false)}
                  confirmText="Yes, Confirm"
                  cancelText="Cancel"
                >
                </CustomModal>
              )}

{message && ( <div className={`message-box ${message.type}`}> {message.text} </div> )}
    </div>
  );
};

export default AllEnteredPage;
