import React from 'react';
import Modal from './Modal';
import './CustomModal.css'; // Your custom CSS

/**
 * Reusable ConfirmModal component
 * 
 * Props:
 * - title (string): The main heading/title of the modal.
 * - subtitle (string): Secondary text or short description.
 * - onConfirm (function): Callback when user clicks "Confirm".
 * - onClose (function): Callback to close the modal (e.g. sets a state in parent).
 * - confirmText (string): Label for the Confirm button. Defaults to 'Confirm'.
 * - cancelText (string): Label for the Cancel button. Defaults to 'Cancel'.
 * - isLoading (bool): If true, shows a loading state on the Confirm button.
 * - children (node): Optional React nodes if you want to render inputs or extra text.
 */
const CustomModal = ({
  title,
  subtitle,
  onConfirm,
  onClose,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  children
}) => {
  
  // Wrapper around confirm handler; you can add any extra logic here if needed.
  const handleConfirm = async () => {
    if (onConfirm) {
      await onConfirm();
    }
  };

  return (
    <Modal onClose={onClose}>
      <div className="confirm-modal">
        
        {/* Heading */}
        {title && <h2 className="h1style">{title}</h2>}
        
        {/* Subtitle or message */}
        {subtitle && <p className="h2style">{subtitle}</p>}

        {/* Any custom content (forms, text, etc.) */}
        {children}

        {/* Action buttons */}
        <div className="actions">
          <button 
            onClick={onClose} 
            className="cancel-button"
            
          >
            {cancelText}
          </button>
          <button 
            onClick={handleConfirm}
            className="confirm-button"
          >
            {confirmText}
          </button>
        </div>

      </div>
    </Modal>
  );
};

export default CustomModal;
