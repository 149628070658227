import React, { useState, useEffect, useRef } from 'react';
import { FaEllipsisV } from 'react-icons/fa';
import QueueService from './QueueService';
import QrChangeModal from './QrChangeModal';
import './QrCodePage.css';

const QrCodePage = ({ token, business_id }) => {
  const [qrCodeUrl, setQrCodeUrl] = useState(null);
  const [businessInfo, setBusinessInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isGenerateButtonVisible, setIsGenerateButtonVisible] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const fetchBusinessInfo = async (business_id) => {
    try {
      const response = await QueueService.GetBusinessInfo(business_id);
      setBusinessInfo({
        business_name: response.business_name,
        logo_url: response.business_metadata.logo_url,
        total_initial_waiting_time: response.queue_info[0].total_initial_waiting_time,
        max_waiting_number: response.queue_info[0].max_waiting_number,
        estimated_wait_time_buffer: response.queue_info[0].estimated_wait_time_buffer
      });
    } catch (error) {
      console.error('Error fetching business info:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchQRCode = async (token) => {
    try {
      const response = await QueueService.GetQRCode(token);
      setQrCodeUrl(response.qrCodeUrl);
    } catch (error) {
      console.error('Error generating QR code:', error);
    }
  };

  const handleGenerateNewQR = async (token) => {
    try {
      const response = await QueueService.GenerateNewQr(token);
      setQrCodeUrl(response.qrCodeUrl); // Update QR code URL after generating new QR code
    } catch (error) {
      console.error('Error generating new QR code:', error);
    }
  };

  useEffect(() => {
    fetchBusinessInfo(business_id);
    fetchQRCode(token);
  }, [token, business_id]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="qr-code-page">
      <button
        className="action-button"
        onClick={() => setOpenDropdown(!openDropdown)}
        style={{ position: 'absolute', top: '15px', right: '10px' }}
      >
        <FaEllipsisV style={{ height: '1.5em' }} />
      </button>

      <div className="dropdown" ref={dropdownRef} style={{ position: 'absolute', top: '35px', right: '10px' }}>
        {openDropdown && (
          <div className="dropdown-menu" style={{ position: 'absolute', right: 0 }}>
            <button onClick={() => setIsModalOpen(true)} style={{ color: 'black' }}>
              Generate New QR
            </button>
          </div>
        )}
      </div>

      {loading ? (
        <div className="loader"></div>
      ) : (
        <>
          {businessInfo && (
            <div className="business-info">
              <img src={businessInfo.logo_url} alt="Business Logo" style={{ maxHeight: '100px' }} />
              <div className="welcome-text">
                <h2>Welcome to {businessInfo.business_name}</h2>
                <p>Please scan the code to get in the queue.</p>
              </div>
            </div>
          )}
          {qrCodeUrl && (
            <div className="qr-code-container">
              <img src={qrCodeUrl} alt="QR Code" className="qr-code-image" />
            </div>
          )}
          {businessInfo && (
            <div className="queue-info">
              <p>
                Estimated Wait time: 
                <strong>
                  {businessInfo.estimated_wait_time_buffer === 0
                    ? `${businessInfo.total_initial_waiting_time}`
                    : `${businessInfo.total_initial_waiting_time}-${
                        businessInfo.total_initial_waiting_time + businessInfo.estimated_wait_time_buffer
                      }`}
                </strong> minutes.
              </p>
              <p>People already in the queue: <strong>{businessInfo.max_waiting_number - 1}</strong></p>
            </div>
          )}
        </>
      )}

      <QrChangeModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleGenerateNewQR}
        token={token}
      />
    </div>
  );
};

export default QrCodePage;