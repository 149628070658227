import React, { useState } from 'react';
import ChatList from './ChatList';
import ChatWindow from './ChatWindow';
import './ChatList.css'; // separate CSS file

const ChatPage = ({token}) => {
  // Store the currently selected phone/user ID
  const [selectedPhone, setSelectedPhone] = useState(null);

  const handleChatClick = (phone) => {
    setSelectedPhone(phone); // Update state with chosen phone
  };

  return (
    <div className="whatsapp-page-container">
      {/* Left sidebar: Chat list */}
      <ChatList onChatClick={handleChatClick} />
      {/* Right side: Chat window */}
      <ChatWindow phone={selectedPhone} token={token} />
    </div>
  );
};

export default ChatPage;
