import React, { useEffect, useState } from 'react';
import ReservationForm from './ReservationForm';
import { useLocation } from 'react-router-dom';
import './QrCodePage.css';
import './CustomerTrackingPage.css';
import EntryScreen from './EntryScreen.js';
const apiUrl = process.env.REACT_APP_API_URL;
const BASE_URL = `${apiUrl}/business`;

const ReservationPage = () => {

  const location = useLocation();
  const [businessMetadata, setBusinessMetadata] = useState(null);
  const [business_id, setBusinessId] = useState(null);
  const [enteredUser, setEnteredUser] = useState(null);
  const [queueInfo, setQueueInfo] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [showThankYou, setShowThankYou] = useState(false);
  const [loading, setLoading] = useState(true);
  const [business_info_all, setBusinessInfoAll] = useState(null);

  
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setBusinessId(queryParams.get('business_id'));
    let isMounted = true; // For cleanup

    const fetchBusinessInfo = async () => {
      // Only proceed if we have a valid business_id
      if (!business_id) {
        console.log('Skipping API call - no business_id yet');
        return;
      }

      
      try {
        const response = await fetch(`${BASE_URL}/business_info?business_id=${business_id}`);
        const result = await response.json();
        
        // Only update state if component is still mounted
        if (isMounted && result.data) {
          setBusinessInfoAll(result.data);
          setBusinessMetadata(result.data.business_metadata);
          const filteredQueueInfo = result.data.queue_info.filter(
            queue => queue.queue_type !== 'reserved'
          );
          setQueueInfo(filteredQueueInfo);
        }
      } catch (error) {
        console.error('Error fetching business information:', error);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    // Reset loading state when business_id changes
    setLoading(true);
    fetchBusinessInfo();

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, [business_id]);

  const handleSubmit = async (formData) => {
    const dataToSubmit = {
      ...formData,
      business_id: business_id, // Use the businessId from the state
    };

    console.log('Form submitted:', dataToSubmit);

    try {
        const response = await fetch(`${BASE_URL}/enqueue_user`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(dataToSubmit),
        });
      
        if (response.ok) {
          const result = await response.json();
          console.log('User enqueued successfully:', result);
      
          if (result.success) {
            setShowThankYou(true);
          }
        } else {
          console.error('Error enqueuing user:', response.statusText);
        }
      } catch (error) {
        console.error('Network error:', error);
      }
  };

  if (loading) {
    return <div className="loader"></div>; // Show loader while fetching data
  }
if (enteredUser) {
  return <EntryScreen business_info={business_info_all} customer_info={userInfo}/>;
}



if (showThankYou) {
    return (
      <div className="thank-you-container">
        <div className="thank-you-icon">✓</div>
        <h2 className="thank-you-title">Thank You for Your Reservation!</h2>
        <p className="thank-you-message">
          Your reservation has been successfully confirmed. We look forward to serving you!
        </p>
      </div>
    );
  }



  return (
    <div>
       {businessMetadata && (
  <div>
     <div className="top-bar">
    <img src="https://i.ibb.co/Nr7qRgH/Waitwhiz-Logo-Pure-White-Background.png" alt="Waitwhiz Logo" className="waitwhiz-logo" />
    <img src={businessMetadata.logo_url} alt="Business Logo" className="business-logo" />
  </div>

    <div style={{ textAlign: 'center', flex: 1 }}>
      <h3>Please fill in the form for Reservation</h3>
    </div>
  </div>
)}


      {business_id ? (
        <div style={{ padding: '0 20px' }}>
          <ReservationForm
            onSubmit={handleSubmit}
            queue_info={queueInfo}
            auto_queue={businessMetadata.automatic_queue_selection}
            usercheck={true}
            isPhoneRequired={true}
            business_id={business_id}
            is_reservation={true}
            brand_id={1}
          />
        </div>
      ) : (
        <div className="loader"></div>
      )}
    </div>

    
  );
};

export default ReservationPage;
