import React from 'react';
import Modal from './Modal';
import './ConfirmEntryModal.css';

const MoveToQueueModal = ({ onClose, onConfirm, customer_name }) => {
  return (
    <Modal onClose={onClose}>
      <div className="confirm-entry-modal">
      <p className="h1style">Confirm move to queue</p>
        <p>Are you sure you want to move customer to queue ({customer_name})?</p>
        <div className="actions">
          <button onClick={onClose} className="cancel-button">Cancel</button>
          <button onClick={onConfirm} className="confirm-button">Yes, Move</button>
        </div>
      </div>
    </Modal>
  );
};

export default MoveToQueueModal;