
import { handleApiError } from './apiErrorHandler';
const apiUrl = process.env.REACT_APP_API_URL;
const BASE_URL = `${apiUrl}/business`;
// const WHATSAPP_BASE_URL = `${apiUrl}/whatsapp`;
const WHATSAPP_BASE_URL = `https://api.staging.waitwhiz.in/whatsapp`;
const URL = `${apiUrl}`;
const URL_AI_CALLING = `${apiUrl}`;




const FetchRecentUsersWithMessages = async () =>  {
  try {
    const response = await fetch(`${WHATSAPP_BASE_URL}/recent_users_with_messages`, {
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error('Failed to fetch recent users with messages');
    }

    const result = await response.json();
    return result.data;
  } catch (error) {
    console.error('Error in FetchRecentUsersWithMessages:', error);
    throw error;
  }
}

// Fetch messages for a specific user
const FetchMessages = async (phone) =>  {
  try {
    const response = await fetch(`${WHATSAPP_BASE_URL}/customer/messages?phone=${phone}`, {
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error('Failed to fetch messages');
    }

    const result = await response.json();
    return result.data;
  } catch (error) {
    console.error('Error in FetchMessages:', error);
    throw error;
  }
}

// Send a message to a specific user
const SendMessage = async(phone, text, token) => {
  try {
    const response = await fetch(`${WHATSAPP_BASE_URL}/send?auth_token=${token}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({ phone, text }),
    });

    if (!response.ok) {
      throw new Error('Failed to send message');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error in SendMessage:', error);
    throw error;
  }
}


const FetchQueues = async (business_id, token) => {
  const response = await fetch(`${BASE_URL}/get_number_of_queues?business_id=${business_id}&auth_token=${token}`, {credentials: 'include'});
  if (!response.ok) {
    throw new Error('Failed to fetch queues');
  }
  const result = await response.json();
  return result.data;
};

const Logout = async() => {
  const response = await fetch(`${BASE_URL}/logout`, {credentials: 'include'});
}

const fetchTables = async (token) => {
  try {
    const response = await fetch(`${BASE_URL}/get_business_tables?auth_token=${token}`, {
      method: 'GET',
      credentials: 'include',
    });
    
    if (!response.ok) {
      throw { status: response.status, message: 'Failed to fetch business tables' };
    }
    
    const result = await response.json();
    return result.data;
  } catch (error) {
    if (handleApiError(error, Logout)) {
      throw new Error('Session expired');
    }
    throw error;
  }
};


const GetBusinesses = async (brand_id) => {
  try {
    const response = await fetch(`${BASE_URL}/get_business_by_brand_id?brand_id=${brand_id}`, {
      method: 'GET',
      credentials: 'include',
    });
    
    if (!response.ok) {
      throw { status: response.status, message: 'Failed to fetch business tables' };
    }
    
    const result = await response.json();
    return result.data;
  } catch (error) {
    if (handleApiError(error, Logout)) {
      throw new Error('Session expired');
    }
    throw error;
  }
};


const getCustomerHistory = async (customer_id, token) => {
  try {
    const response = await fetch(`${BASE_URL}/customer_visits?customer_id=${customer_id}&auth_token=${token}`, {
      method: 'GET',
      credentials: 'include',
    });
    const result = await response.json();
    return result.data;
    
  } catch (error) {
    console.error('Error fetching tables:', error);

  }
};



const CustomerInfo = async(params, token) => {
  let url = `${BASE_URL}/customer_info?start_time=${params.start_time}&end_time=${params.end_time}&auth_token=${token}`;
  const response = await fetch(url, {credentials: 'include'});
  
  if (!response.ok) {
    throw new Error('Failed to fetch users');
  }
  const result = await response.json();
  return result.data;
};


const UpdateQueueNumber = async (token, queue_id, estimated_wait_time, table_first_empty_time ) => {
  const response = await fetch(`${BASE_URL}/update_queue_estimated_time?auth_token=${token}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      queue_id,
      estimated_wait_time,
      table_first_empty_time
    })
  });

  if (!response.ok) {
    throw new Error('Failed to mark user as entered');
  }

  const result = await response.json();
  return result;
};


const add_note = async (token, id, text) => {
  const response = await fetch(`${BASE_URL}/create_or_update_notes?auth_token=${token}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      text: text,
      entity_id: id,
      entity_type: "business_customer_mappings"

    })
  });

  if (!response.ok) {
    throw new Error('Failed to mark user as entered');
  }

  const result = await response.json();
  return result;
};





const UpdateQueueStatus = async (token, queue_id, status) => {
  const response = await fetch(`${BASE_URL}/create_or_update_business_queue?auth_token=${token}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      id: queue_id,
      mark_as_active: status
    })
  });

  if (!response.ok) {
    throw new Error('First remove/enter waiting customers');
  }

  const result = await response.json();
  return result;
};


const makeCall = async (business_customer_mapping_id, token) => {
  
    const response = await fetch(`${BASE_URL}/mark_table_ready?mapping_id=${business_customer_mapping_id}&auth_token=${token}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });
    console.log(response);
    if (!response.ok) {
      throw new Error('Cannot Call');
    }
    const result = await response.json();
    return result.data;
};



const FetchUsers = async (business_id, business_user_id, queue, token) => {
  let url = `${BASE_URL}/list_items_in_queue?business_id=${business_id}&business_user_id=${business_user_id}&auth_token=${token}`;
  if (queue !== 'All') {
    url += `&queue_id=${queue}`;
  }
  const response = await fetch(url, {credentials: 'include'});
  if (!response.ok) {
    throw new Error('Failed to fetch users');
  }
  const result = await response.json();
  return result.data;
};

const GetBusinessInfo = async(business_id) => {
  
  try {
    let url = `${BASE_URL}/business_info?business_id=${business_id}`
    const response = await fetch(url, {credentials: 'include'});
    
    if (!response.ok) {
      throw { status: response.status, message: 'Failed to fetch business info' };
    }
    
    const result = await response.json();
    return result.data;
  } catch (error) {
    if (handleApiError(error, Logout)) {
      throw new Error('Session expired');
    }
    throw error;
  }
};

const AddUser = async (user, token) => {
  const response = await fetch(`${BASE_URL}/add_or_update_user?auth_token=${token}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
    credentials: 'include'
  });
  if (!response.ok) {
    throw new Error('Failed to add user');
  }
  const result = await response.json();
  return result;
};

const UpdateBusinessInfo = async (details, business_id, token) => {
  const request_body = {
    'id': business_id,
    'business_metadata': details
  }
  const response = await fetch(`${BASE_URL}/create_or_update?auth_token=${token}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request_body),
    credentials: 'include'
  });
  if (!response.ok) {
    throw new Error('Failed to add user');
  }
  const result = await response.json();
  return result;
};

const EditUser = async (user, token) => {
  const response = await fetch(`${BASE_URL}/add_or_update_user?auth_token=${token}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
    credentials: 'include'
  });
  if (!response.ok) {
    throw new Error('Failed to edit user');
  }
  const result = await response.json();
  return result;
};

const DeleteUser = async (business_customer_mapping_id, removed_reason, business_id) => {
  const response = await fetch(`${BASE_URL}/mark_customer_delete?business_id=${business_id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      business_customer_mapping_id,
      removed_reason
    })
  });

  if (!response.ok) {
    throw new Error('Failed to delete user');
  }
  const result = await response.json();
  return result;
};

const moveUserToQueue = async (business_customer_mapping_id, token) => {
  const response = await fetch(`${BASE_URL}/mark_reserved_to_waiting?auth_token=${token}&business_customer_mapping_id=${business_customer_mapping_id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      business_customer_mapping_id
    })
  });

  if (!response.ok) {
    throw new Error('Failed to move user to queue');
  }
  const result = await response.json();
  return result;
};

const markUserReservationConfirmed = async (mapping_id, token) => {
  const response = await fetch(`${BASE_URL}/approve_reservation?auth_token=${token}&mapping_id=${mapping_id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      mapping_id
    })
  });

  if (!response.ok) {
    throw new Error('Failed to approve reservation');
  }
  const result = await response.json();
  return result;
};

const markUserReservationRejected = async (mapping_id, token) => {
  const response = await fetch(`${BASE_URL}/reject_reservation?auth_token=${token}&mapping_id=${mapping_id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      mapping_id
    })
  });

  if (!response.ok) {
    throw new Error('Failed to reject reservation');
  }
  const result = await response.json();
  return result;
};


const MarkUserAsEntered = async (business_customer_mapping_id, token, table_id, table_no) => {
  const response = await fetch(`${BASE_URL}/mark_customer_entered?auth_token=${token}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      business_customer_mapping_id,
      table_id,
      table_no
    })
  });

  if (!response.ok) {
    throw new Error('Failed to mark user as entered');
  }

  const result = await response.json();
  return result;
};



const GetQRCode = async(token) => {
  const response = await fetch(`${BASE_URL}/show_qr?auth_token=${token}`)
  if (!response.ok) {
    throw new Error('Failed to fetch QR code');
  }
  const blob = await response.blob();
  const qrCodeUrl = URL.createObjectURL(blob);
  return { qrCodeUrl };
};

const GenerateNewQr = async(token) => {
  const response = await fetch(`${BASE_URL}/generate_qr?auth_token=${token}`)
  if (!response.ok) {
    throw new Error('Failed to fetch QR code');
  }
  const blob = await response.blob();
  const qrCodeUrl = URL.createObjectURL(blob);
  return { qrCodeUrl };
};


const AiCall = async(phone_number, mapping_id) => {
  const response = await fetch(`${URL_AI_CALLING}/ai_calling/initiate_call?phone_number=${phone_number}&mapping_id=${mapping_id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      phone_number,
      mapping_id
    })
  });

  if (!response.ok) {
    throw new Error('Failed to Call');
  }

  const result = await response.json();
  return result;
};



const SendWhatsappMessage = async (phone, text, token) => {
  const response = await fetch(`${WHATSAPP_BASE_URL}/message/send?auth_token=${token}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({
      phone,
      text
    })
  });

  if (!response.ok) {
    throw new Error('Failed to send message');
  }

  const result = await response.json();
  return result.data;
};


export default {
  FetchQueues,
  GetBusinessInfo,
  FetchUsers,
  AddUser,
  EditUser,
  DeleteUser,
  MarkUserAsEntered,
  GetQRCode,
  CustomerInfo,
  UpdateQueueNumber,
  Logout,
  UpdateQueueStatus,
  add_note,
  makeCall,
  UpdateBusinessInfo,
  fetchTables,
  moveUserToQueue,
  getCustomerHistory,
  markUserReservationConfirmed,
  markUserReservationRejected,
  GenerateNewQr,
  SendMessage,
  FetchRecentUsersWithMessages,
  FetchMessages,
  SendWhatsappMessage,
  AiCall,
  GetBusinesses
};
