import React from 'react';
import Modal from './Modal';
import './ConfirmEntryModal.css';

const ApproveReservationModal = ({ onClose, onConfirm, customer_name}) => {
  return (
    <Modal onClose={onClose}>
      <div className="confirm-entry-modal">
        <h2>Approve Reservation</h2>
        <p>Are you sure you want to approve the reservation for <strong>{customer_name}</strong>?</p>
        <div className="actions">
          <button className="cancel-button" onClick={onClose}>Cancel</button>
          <button className="confirm-button" onClick={onConfirm}>Confirm</button>
        </div>
      </div>
    </Modal>
  );
};

export default ApproveReservationModal; 