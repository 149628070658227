import React, { useEffect, useState } from 'react';
import QueueService from './/QueueService'; 
// Or ChatService, whichever you have

const ChatList = ({ onChatClick }) => {
  const [chats, setChats] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchChats = async () => {
      try {
        // Adjust the service function name to your actual endpoint
        const data = await QueueService.FetchRecentUsersWithMessages();
        setChats(data.messages); // 'messages' from the JSON
      } catch (err) {
        console.error('Error fetching chats:', err);
        setError('Failed to load chats');
      }
    };

    fetchChats();
  }, []);

  if (error) {
    return <p style={{ margin: '1rem' }}>Error: {error}</p>;
  }

  // When a chat is clicked, call the parent function
  const handleChatItemClick = (userId) => {
    onChatClick(userId);
  };

  return (
    <div className="chat-list-container">
      <div className="chat-list-header">Chats</div>
      <ul className="chat-list">
        {chats.map((chat) => (
          <li
            key={chat.user_id}
            className="chat-list-item"
            onClick={() => handleChatItemClick(chat.user_id)}
          >
            <p className="chat-user-id">{chat.name}</p>
            <p className="chat-message-text">{chat.message_text}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ChatList;
