// src/utils/apiErrorHandler.js

export const handleApiError = (error, Logout) => {
    if (error.status === 401 || error.statusCode === 401) {
      // Clear all local storage
      localStorage.clear();
      
      // Force redirect to login page
      window.location.href = '/';
      
      // Call logout handler if provided
      if (Logout) {
        Logout();
      }
      
      return true; // indicates that a 401 was handled
    }
    return false; // indicates that this wasn't a 401 error
  };