import React, { useState } from 'react';
import './QrChangeModal.css';

const QrChangeModal = ({ isOpen, onClose, onSubmit, token }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirmationText, setConfirmationText] = useState('');

  const handleGenerateNewQR = async () => {
    setIsSubmitting(true);
    try {
      await onSubmit(token);
      onClose(); // Close modal after success
    } catch (error) {
      console.error('Error generating new QR code:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const isGenerateButtonEnabled = confirmationText.toLowerCase() === 'generate';

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Are you sure you want to change the printed QR code?</h2>
        <p>Please type "generate" in the box below to confirm.</p>
        <input
          type="text"
          placeholder="Type here"
          value={confirmationText}
          onChange={(e) => setConfirmationText(e.target.value)}
          className="confirmation-input"
        />
       
        <div className="modal-actions">
          <button onClick={onClose} disabled={isSubmitting}>
            Cancel
          </button>
          <button
        
            onClick={handleGenerateNewQR}
            disabled={!isGenerateButtonEnabled || isSubmitting}
          >
            {isSubmitting ? 'Generating...' : 'Generate'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default QrChangeModal;
